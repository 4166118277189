<template>
  <el-main>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="用户姓名：">
        <el-input size="small" v-model="searchForm.name" placeholder="请输入用户姓名"></el-input>
      </el-form-item>
      <el-form-item label="用户手机：">
        <el-input size="small" type="number" v-model="searchForm.u_mobile" placeholder="请输入用户手机"></el-input>
      </el-form-item>
      <el-form-item label="订单号：">
        <el-input size="small" v-model="searchForm.order_sn" placeholder="请输入订单号"></el-input>
      </el-form-item>
      <el-form-item label="类型：">
        <el-select v-model="searchForm.type" size="small" placeholder="请选择" clearable>
          <el-option label="全部" :value="0"></el-option>
          <el-option label="商品使用" :value="-1"></el-option>
          <el-option label="服务使用" :value="-2"></el-option>
          <el-option label="到店付款使用" :value="-3"></el-option>
          <el-option label="获取" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="来源：">
        <el-select v-model="searchForm.source" size="small" placeholder="请选择" clearable>
          <el-option label="全部" :value="0"></el-option>
          <el-option label="积分交换" :value="1"></el-option>
          <el-option label="充值VIP赠送" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间：">
        <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="用户姓名" align="center" width="150"></el-table-column>
      <el-table-column prop="u_mobile" label="用户手机" align="center" width="150"></el-table-column>
      <el-table-column prop="coupon_name" label="优惠券名称" align="center" width="150"></el-table-column>
      <el-table-column prop="per_consume_full" label="优惠券规则" align="center" width="200">
        <template v-slot="{ row }">
          每消费满{{ row.per_consume_full }}减{{ row.subtract }}
        </template>
      </el-table-column>
      <el-table-column prop="source" label="来源" align="center" width="200">
        <template v-slot="{ row }">
          {{ row.source == 1 ? '积分兑换' : row.source == 2 ? '充值VIP赠送' : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型" align="center" width="200">
        <template v-slot="{ row }">
          {{ row.type == -1 ? '商品使用' : row.type == -2 ? '服务使用' : row.type == -3 ? '到店付款使用' : row.type == 1 ? '获取' : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="order_sn" label="订单号" align="center">
        <template v-slot="{ row }">
          {{ row.order_sn ? row.order_sn : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="200" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import _ from 'lodash';

export default {
  components: {
    Paging,
  },
  data() {
    return {
      list: [],
      total_number: 0,
      searchForm: {
        page: 1,
        rows: 10,
        name: '',
        u_mobile: '',
        order_sn: '',
        type: 0,
        source: 0,
        start_time: '',
        end_time: '',
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        name: '',
        u_mobile: '',
        order_sn: '',
        type: 0,
        source: 0,
        start_time: '',
        end_time: '',
      };
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.repair.Coupon.log, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .sortList {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
}
.el-main {
  background: #fff;
  display: flex;
  flex-direction: column;
}
</style>
